$(function () {
  const popup = document.querySelector(".popup");
  const popupTriggers = document.querySelectorAll(".popup-trigger");
  const popupCloseButton = document.querySelectorAll(".popup__close");

  // close on overlay click
  if (popup) {
    popup.addEventListener("click", handleOverlayClose);
  }

  for (popupTrigger of popupTriggers) {
    popupTrigger.addEventListener("click", (event, args) => {
      const targetClass = event.target.getAttribute("target");
      const target = document.querySelector(`.${targetClass}`);

      togglePopupClass(target);
      bindPopupEsc(target);
    });
  }

  //close popup
  function handleEscClose(event) {
    const ESC_KEYCODE = 27;

    if (event.keyCode === ESC_KEYCODE) {
      document.querySelector(".popup_opened").classList.remove("popup_opened");
      window.location.reload();
    }
  }

  function handleOverlayClose(event) {
    if (popup === event.target && popup.classList.contains("popup")) {
      closePopup();
    }
  }

  function bindPopupEsc() {
    document.addEventListener("keydown", handleEscClose);
  }

  function closePopup() {
    popup.classList.remove("popup_opened");

    document.removeEventListener("keydown", handleEscClose);
  }

  function togglePopupClass(element) {
    element.classList.toggle("popup_opened");
  }

  popupCloseButton.forEach((element) => {
    element.addEventListener("click", (event) => {
      const popupElement = event.target.closest(".popup");

      togglePopupClass(popupElement);
    });
  });
});

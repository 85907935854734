$(function () {
  const rightSidebar = document.querySelector(".right-sidebar");
  const notificationsButton = document.querySelector(".header__notifications");

  function openSidebar() {
    rightSidebar.classList.toggle("open-sidebar");
  }

  if (notificationsButton) {
    notificationsButton.addEventListener("click", openSidebar);
  }

  $(".notification__unread a").on(
    "ajax:success",
    function (e, data, status, xhr) {
      const counter = $("#notifications-counter");

      if (counter) {
        if ($(counter).text()) {
          let count = Number($(counter).text());
          count -= 1;

          if (count === 0) {
            $(counter).parent().remove();
          } else {
            $(counter).text(count);
          }
        }
      }

      return $(this).parents(".notification__item:first").remove();
    }
  );
});

function checkNotesAnchor() {
  const matches = location.href.match(/event\-(\d+)\-notes/);

  if (matches && matches.length === 2) {
    const id = matches[1];
    const element = document.getElementById(`event-${id}-notes`);

    if (element) {
      const url = location.href;

      element.click();
      window.history.pushState({}, "", url.slice(0, url.indexOf("#")));
    }
  }
}

$(function () {
  checkNotesAnchor();

  $(".past-session-note").on("click", () => {
    setTimeout(() => {
      checkNotesAnchor();
    }, 100);
  });
});

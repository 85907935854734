$(function () {
  let proto;

  if ($(".forum-container").length) {
    proto = $("#comment-proto-form").html();

    $(".comments__reply-btn").on("click", function (e) {
      let form;

      e.preventDefault();
      form = $(this)
        .parents(".comments__wrapper:first")
        .find(".reply-placement");

      if ($(form).html() === "") {
        $(form).html(proto);
        // $(form).find(".redactor-simple").bindSimpleRedactor();

        if ($(this).data("parent")) {
          $(form).find(".comment_parent_id input").val($(this).data("parent"));
        }
      } else {
        $(form).html("");
      }
      return false;
    });

    $(".comments .edit a").on("click", function (e) {
      let comment_content, form_placement, save_url;

      e.preventDefault();
      form_placement = $(this)
        .parents(".comment-item:first")
        .find(".edit-placement");
      comment_content = $(this)
        .parents(".comment-item:first")
        .find(".redactor-content-simple")
        .html();
      save_url = $(this).attr("href");

      if ($(form_placement).html() === "") {
        $(form_placement).html(proto);
        $(form_placement).find(".redactor-simple").html(comment_content);
        // .bindSimpleRedactor();

        $(form_placement).find("form").get(0).setAttribute("action", save_url);
        $(form_placement)
          .find("input[type=submit]")
          .attr("value", "Update comment");

        if ($(this).data("parent")) {
          $(form_placement).find(".proto-parent").val($(this).data("parent"));
        }
      } else {
        $(form_placement).html("");
      }

      return false;
    });
  }

  $(".comments__delete a").on("ajax:success", function (e, data, status, xhr) {
    return $(this).parents(".comments__wrapper:first").remove();
  });

  const chatlog = $(".chatlogs");
  if (chatlog && chatlog.length > 0) {
    chatlog.scrollTop(chatlog[0].scrollHeight);
  }
});
